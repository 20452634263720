import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {environmentExadel} from 'src/environments/environment.exadel';

@Injectable({
  providedIn: 'root',
})
export class OperationHandlerService {

  constructor(protected httpClient: HttpClient) {
  }

  public handleUsingGET(): Observable<any> {

    return this.httpClient
        .get<any>(`${environmentExadel.api}/actuator/info`);

  }

}
