import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared.module';
import {IconSnackBarComponent} from './iconSnackBar.component';

@NgModule({
  declarations: [
    IconSnackBarComponent,
  ],
  imports: [
    SharedModule,
  ],
  exports: [
    IconSnackBarComponent,
  ],
})

export class IconSnackBarModule {

}
