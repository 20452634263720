import {MatSnackBarRef, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'icon-snack-bar',
  templateUrl: './iconSnackBar.component.html',
  styleUrls: ['./iconSnackBar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class IconSnackBarComponent {
  constructor(
        public snackBarRef: MatSnackBarRef<IconSnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) { }
}
