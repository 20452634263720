import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApproveTrainingModel, EmailModel, ICoachSubscriber, IKeyValueData, IUserProfileStatisticsCount, LeavingDetails, LessonBusyModel, TrainingListModel, UserModel, UserModelSecurity} from 'src/app/core/models';
import {Observable} from 'rxjs';
import {environmentExadel} from 'src/environments/environment.exadel';
import {UserAssignedTestModel} from '../../models/users/UserAssignedTestModel';
import {UserPassedTestModel} from '../../models/users/UserPassedTest';
import {IPaginatedResponse} from '../../models';
import {UserStatistic} from '../../models/users/userStatistic.interface';

const headers = new HttpHeaders({'content-type': 'application/json'});

@Injectable({
  providedIn: 'root',
})

export class UserService {

  constructor(
      protected httpClient: HttpClient,
  ) {
  }

  public getActualTraining(params: IKeyValueData, data?: IKeyValueData): Observable<IPaginatedResponse<TrainingListModel>> {

    return this.httpClient
        .get<IPaginatedResponse<TrainingListModel>>(`${environmentExadel.api}/user_controller/actualTraining/${data?.userId}`, {params});

  }

  public getAllExCoach(): Observable<Array<UserModel>> {

    return this.httpClient
        .get<Array<UserModel>>(`${environmentExadel.api}/user_controller/get_all_ex_coach`);

  }

  public getAllUser(): Observable<Array<UserModel>> {

    return this.httpClient
        .get<Array<UserModel>>(`${environmentExadel.api}/user_controller/get_all_users`);

  }

  public getUsersAssignedToHr(): Observable<UserModel[]> {

    return this.httpClient
        .get<UserModel[]>(`${environmentExadel.api}/user_controller/sort-by-hr`);

  }

  public getUsersAvailableForAssigmentToTest(trainingId: number[]): Observable<Array<UserModel>> {

    return this.httpClient
        .post<Array<UserModel>>(`${environmentExadel.api}/user_controller/get_available_users `, trainingId);

  }

  public getAllUsersBelongCoach(coachId: number): Observable<Array<UserModel>> {

    return this.httpClient
        .get<Array<UserModel>>(`${environmentExadel.api}/user_controller/get_all_users_belong_coach/${coachId}`);

  }

  public getApproveTraining(params: IKeyValueData, data?: IKeyValueData): Observable<IPaginatedResponse<ApproveTrainingModel>> {

    return this.httpClient
        .get<IPaginatedResponse<ApproveTrainingModel>>(`${environmentExadel.api}/user_controller/approveTraining/${data?.userId}`, {params});

  }

  public getAttendedTraining(params: IKeyValueData, data?: IKeyValueData): Observable<IPaginatedResponse<TrainingListModel>> {

    return this.httpClient
        .get<IPaginatedResponse<TrainingListModel>>(`${environmentExadel.api}/user_controller/visitedTraining/${data?.userId}`, {params});

  }

  public getAttendedUserTrainingsAndTests(endDate: number, startDate: number, userId: number, showForeignLanguage: boolean): Observable<UserStatistic[]> {

    return this.httpClient
        .get<UserStatistic[]>(`${environmentExadel.api}/user_controller/attendedTrainings/${userId}/${startDate}/${endDate}?showLang=${showForeignLanguage}`);

  }

  public getCoaches(): Observable<Array<UserModel>> {

    return this.httpClient
        .get<Array<UserModel>>(`${environmentExadel.api}/user_controller/get_coaches`);

  }

  public getAllActiveUsers(): Observable<UserModel[]> {

    return this.httpClient
        .get<UserModel[]>(`${environmentExadel.api}/user_controller/get_all_active_users`);

  }

  public getCreds(): Observable<UserModelSecurity> {

    return this.httpClient
        .get<UserModelSecurity>(`${environmentExadel.api}/user_controller/get_creds`);

  }

  public getReasonOFLeave(trainingId: number, userId: number): Observable<LeavingDetails> {

    return this.httpClient
        .get<LeavingDetails>(`${environmentExadel.api}/user_controller/get_reason_of_leave/${userId}/${trainingId}`);

  }

  public getUserInfo(userId: number): Observable<UserModel> {

    return this.httpClient
        .get<UserModel>(`${environmentExadel.api}/user_controller/user_info/${userId}`);

  }

  public getWaitingTraining(params: IKeyValueData, data?: IKeyValueData): Observable<IPaginatedResponse<TrainingListModel>> {

    return this.httpClient
        .get<IPaginatedResponse<TrainingListModel>>(`${environmentExadel.api}/user_controller/waitingTraining/${data?.userId}`, {params});

  }

  public grantAdminRights(userId: number): Observable<any> {

    return this.httpClient
        .get<any>(`${environmentExadel.api}/user_controller/grant_admin_rights/${userId}`);

  }

  public hasCoachLessonInThisTime(coachId: number, lessonBusyModel: LessonBusyModel[]): Observable<any> {

    return this.httpClient
        .post<any>(
            `${environmentExadel.api}/user_controller/has-coach/${coachId}/lesson`,
            lessonBusyModel,
        );

  }

  public isCoachSubscriber(lessonModel: ICoachSubscriber): Observable<any> {

    return this.httpClient
        .post<any>(
            `${environmentExadel.api}/user_controller/is-coach-subscriber`,
            lessonModel,
        );

  }

  public hasUserLesson(trainingId: number, userId: number, checkCoachCross: boolean): Observable<any> {

    const checkCoach = `${checkCoachCross}`;

    return this.httpClient
        .get<any>(`${environmentExadel.api}/user_controller/has_user_lesson/${userId}/${trainingId}`,
            {params: {checkCoach}, headers},
        );

  }

  public hideEnglish(userId: number): Observable<any> {

    return this.httpClient
        .get<any>(`${environmentExadel.api}/user_controller/hideEnglish/${userId}`);

  }

  public isHideEnglish(userId: number): Observable<boolean> {

    return this.httpClient
        .get<boolean>(`${environmentExadel.api}/user_controller/isHideEnglish/${userId}`);

  }

  public isSubscribe(userId: number): Observable<boolean> {

    return this.httpClient
        .get<boolean>(`${environmentExadel.api}/user_controller/isSubscribe/${userId}`);

  }

  public logout(): Observable<any> {

    return this.httpClient
        .get<any>(`${environmentExadel.api}/user_controller/logout`);

  }

  public sendEmailAdmin(emailModel: EmailModel): Observable<any> {

    return this.httpClient
        .post<any>(
            `${environmentExadel.api}/user_controller/send_admin_email`,
            emailModel,
        );

  }

  public sendEmailRunner(emailModel: EmailModel): Observable<any> {

    return this.httpClient
        .post<any>(
            `${environmentExadel.api}/user_controller/send_runner_email`,
            emailModel,
        );

  }

  public setPhone(id: number, phone: string): Observable<any> {

    return this.httpClient
        .get<any>(`${environmentExadel.api}/user_controller/set_phone/${id}/${phone}`);

  }

  public subscribe(userId: number): Observable<any> {

    return this.httpClient
        .get<any>(`${environmentExadel.api}/user_controller/subscribe/${userId}`);

  }

  public takeAdminRights(userId: number): Observable<any> {

    return this.httpClient
        .get<any>(`${environmentExadel.api}/user_controller/deprive_admin_rights/${userId}`);

  }

  public unHideEnglish(userId: number): Observable<any> {

    return this.httpClient
        .get<any>(`${environmentExadel.api}/user_controller/unHideEnglish/${userId}`);

  }

  public unSubscribe(userId: number): Observable<any> {

    return this.httpClient
        .get<any>(`${environmentExadel.api}/user_controller/unsubscribe/${userId}`);

  }

  public getAssignedTests(params: IKeyValueData, data?: IKeyValueData): Observable<IPaginatedResponse<UserAssignedTestModel>> {
    return this.httpClient
        .get<IPaginatedResponse<UserAssignedTestModel>>(`${environmentExadel.api}/tests/assignments/active/${data?.userId}`, {params});
  }

  public getPassedTests(params: IKeyValueData, data?: IKeyValueData): Observable<IPaginatedResponse<UserPassedTestModel>> {
    return this.httpClient
        .get<IPaginatedResponse<UserPassedTestModel>>(`${environmentExadel.api}/tests/assignments/passed/${data?.userId}`, {params});
  }

  public getStatisticsCount(userId: number): Observable<IUserProfileStatisticsCount> {
    return this.httpClient
        .get<IUserProfileStatisticsCount>(`${environmentExadel.api}/user_controller/${userId}/profile_count`);
  }

}
