import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class EventsService implements OnDestroy {

  // DECLARATIONS

  public readNews$ = new Subject<number>();
  public updateNewsCount$ = new Subject<number>();

  // DATA

  public showProgressBar: boolean = false;
  public loading$ = new Subject<boolean>();
  public modalLoading$ = new Subject<boolean>();
  public subLoading$ = new Subject<boolean>();
  // CORE

  constructor() {

  }

  ngOnDestroy() {

  }

  // DEFAULT

  public setProgressState(state: boolean) {
    this.showProgressBar = state;
    this.loading$.next(state);
  }

  public setSubState(state: boolean) {
    this.subLoading$.next(state);
  }

  public setModalProgressState(state: boolean) {
    this.showProgressBar = state;
    this.modalLoading$.next(state);
  }

}
