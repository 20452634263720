import {StateObject, Transition, TransitionService} from '@uirouter/core';
import {OauthService} from '../../services/other/oauth.service';

export default function isAdminGuard(transitionService: TransitionService) {

  const criteria = {
    to(state: StateObject) {
      return state.data && state.data.canActivate && state.data.canActivate.isAdminGuard;
    },
  };

  const redirect = (transition: Transition) => {
    const $state = transition.router.stateService;
    const oauthService: OauthService = transition.injector().get(OauthService);


    if (oauthService.userRoles.isAdmin) {
      return;
    }
    return $state.target('app-assessment');
  };

  // @ts-ignore
  transitionService.onBefore(criteria, redirect, {priority: 999});
}
