import {StateObject, Transition, TransitionService} from '@uirouter/core';
import {OauthService} from '../../services/other/oauth.service';

export default function finishedTest(transitionService: TransitionService) {

  const requiresFinishedTestCriteria = {

    to(state: StateObject) {

      return state.data && state.data.canActivate && state.data.canActivate.finishedTest;

    },

  };

  const redirectToPassedTests = (transition: Transition) => {

    const $state = transition.router.stateService;

    const oauthService: OauthService = transition.injector().get(OauthService);

    return $state.target('app-profile.passed', {id: oauthService.userCredsData.userId});

  };

  // @ts-ignore
  transitionService.onBefore(requiresFinishedTestCriteria, redirectToPassedTests, {priority: 100});

}

