import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dots',
  pure: true,
})
export class DotsPipe implements PipeTransform {

  constructor() {}


  public transform(value: string, max: number = 25): string {
    if (value.length <= max) {
      return value;
    }

    return `${value.slice(0, max - 3)}...`;
  }
}
