import {StateObject, Transition, TransitionService} from '@uirouter/core';
import {OauthService} from '../../services/other/oauth.service';

export default function loginPageGuard(transitionService: TransitionService) {

  const isLoggedInCriteria = {

    to(state: StateObject) {

      return state.data && state.data.canActivate && state.data.canActivate.loginPageGuard;

    },

  };

  const redirectToMyTrainings = (transition: Transition) => {

    const $state = transition.router.stateService;

    const oauthService: OauthService = transition.injector().get(OauthService);

    if (oauthService.isLoggedIn && oauthService.userCredsData) {

      return $state.target('app-my-trainings', {userTypesFilter: 'student'});

    }

  };

  // @ts-ignore
  transitionService.onBefore(isLoggedInCriteria, redirectToMyTrainings, {priority: 100});

}
