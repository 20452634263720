import {StateObject, Transition, TransitionService} from '@uirouter/core';
import {OauthService} from '../../services/other/oauth.service';

export default function auth(transitionService: TransitionService) {

  const requiresAuthCriteria = {

    to(state: StateObject) {

      return state.data && state.data.canActivate && state.data.canActivate.authGuard;

    },

  };

  const redirectToLogin = (transition: Transition) => {

    const $state = transition.router.stateService;

    const oauthService: OauthService = transition.injector().get(OauthService);

    if (!oauthService.isLoggedIn || !oauthService.userCredsData) {

      return $state.target('app-login');

    }

    if (oauthService.userRoles.isAnonymous) {
      return $state.target('app-rolelessUserPage');
    }

  };

  // @ts-ignore
  transitionService.onBefore(requiresAuthCriteria, redirectToLogin, {priority: 100});

}

