import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeByDomSanitizer',
  pure: true,
})

export class SanitizeByDomSanitizerPipe implements PipeTransform {

  constructor(
      private sanitizer: DomSanitizer,
  ) {

  }

  transform(value: string, type: 'html' | 'url') {

    if (type === 'html') {

      return this.sanitizer.bypassSecurityTrustHtml(value);

    }

    if (type === 'url') {

      return this.sanitizer.bypassSecurityTrustResourceUrl(value);

    }

  }

}
