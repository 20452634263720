import {Injectable} from '@angular/core';
import {OverlayContainer} from '@angular/cdk/overlay';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {fromEvent} from 'rxjs';
import {takeWhile} from 'rxjs/operators';
import {IconSnackBarComponent} from 'src/app/components/shared/components/iconSnackBar/iconSnackBar.component';

@Injectable({
  providedIn: 'root',
})

export class SnackBarMessagesService {

  // DECLARATIONS

  // DATA

  snackbars: MatSnackBarConfig[] = [];
  cdkOverlayContainer: HTMLElement;
  // CORE

  constructor(
      private _snackBar: MatSnackBar,
      private overlayContainer: OverlayContainer,
  ) {
    this.cdkOverlayContainer = this.overlayContainer.getContainerElement();
  }

  // DEFAULT

  public showSuccessMessage(text: string): void {

    this.snackbars.push({
      data: {
        message: text,
      },
    });

    this.showSnackBar();
  }

  public showWarningMessage(text: string): void {

    this.snackbars.push({
      data: {
        message: text,
      },
      panelClass: '-warning',
    });

    this.showSnackBar();
  }

  public showErrorMessage(text: string): void {

    this.snackbars.push({
      data: {
        message: text,
        error: true,
      },
      panelClass: '-error',
    });

    this.showSnackBar();
  }


  openSnackBar(config: any) {
    this._snackBar.openFromComponent(IconSnackBarComponent, config);
  }

  shiftAndShowSnackbar(): void {

    this.snackbars.shift();

    if (this.snackbars.length > 0) {

      this.openSnackBar(this.snackbars[0]);

    }

  }

  showSnackBar() {

    if (this.snackbars.length === 1) {
      this.openSnackBar(this.snackbars[0]);
    }

    if (this.snackbars.length >= 2) {

      if (this.cdkOverlayContainer.querySelector('snack-bar-container') === null) {

        this.shiftAndShowSnackbar();

      } else {

        fromEvent(this.cdkOverlayContainer, 'DOMSubtreeModified')
            .pipe(takeWhile(() => checkSubscription()))
            .subscribe(() => {

              if (this.cdkOverlayContainer.querySelector('snack-bar-container') === null) {

                this.shiftAndShowSnackbar();

              }

            });

        const checkSubscription = (): boolean => {

          return this.snackbars.length !== 1;

        };

      }

    }

  }

}
