<footer class='footer'>

    <div class="footer-company-info">
        <span>© 2016-{{dateNow | date: formatsDateYear}} {{'FOOTER.EXADEL' | translate}}.</span>
    </div>

    <div class="footer-project-info" *ngIf="actuatorInfoData">
        <span>{{actuatorInfoData["active-profiles"][0]}}</span>
    </div>

    <div class="footer-project-support">
        <a href="mailto:tms-mbox@exadel.com" rel="noopener">{{'FOOTER.CONTACT' | translate}}</a>
    </div>

</footer>
