<div class="snackbar-wrapper">
    <div class="snackbar-info">        
        <mat-icon>{{data?.error ? 'cancel' : 'check_circle'}}</mat-icon>
        <span>
            {{ data?.message }}
        </span>
    </div>
    <div class="snackbar-dismiss">
        <mat-icon (click)="snackBarRef.dismiss()">
            close
        </mat-icon>
    </div>
</div>
