import {TransitionService} from '@uirouter/core';
import {RouterService} from './router.service';
import {ReplaySubject} from 'rxjs';
import {EventsService} from '../services/other/events.service';

export default function routerTransitionOnCreateConfig(transitionService: TransitionService) {

  transitionService.onCreate({}, (transition) => {

    const eventsService: EventsService = transition.injector().get(EventsService);

    const routerService: RouterService = transition.injector().get(RouterService);

    const transitionTo = transition.to();

    if (routerService.onCreateStateDeclaration && transitionTo.name !== routerService.onCreateStateDeclaration.name) {

      routerService.routeDestroyed$.next(true);
      routerService.routeDestroyed$.complete();

      routerService.routeDestroyed$ = new ReplaySubject(1);

    }

    routerService.onCreateStateDeclaration = transitionTo;

    eventsService.setProgressState(false);

  });

}
