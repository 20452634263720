import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "millisecondsToHours",
    pure: true,
})
export class MillisecondsToHoursPipe implements PipeTransform {
    constructor() {}

    public transform(ms: number): string {
        let seconds: number = ms / 1000;
        let hours = Math.floor(seconds / 3600)
            .toString()
            .padStart(2, "0");
        seconds = seconds % 3600;
        let minutes = Math.floor(seconds / 60)
            .toString()
            .padStart(2, "0");
        seconds = seconds % 60;

        let sec = seconds
            .toString()
            .padStart(2, "0");

        return `${hours}:${minutes}:${sec}`;
    }
}
