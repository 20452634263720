import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {OauthService} from './core/services/other/oauth.service';
import {UIRouter} from '@uirouter/angular';
import {EventsService} from './core/services/other/events.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AppComponent implements OnInit {

  constructor(
      public oauthService: OauthService,
      public router: UIRouter,
      public eventsService: EventsService,
      private translate:TranslateService,
  ) { }

  ngOnInit():void {
    this.translate.setDefaultLang('en');
  }
}
