import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'countDaysBeforeStart',
  pure: true,
})

export class CountDaysBeforeStartPipe implements PipeTransform {

  constructor() {

  }

  transform(nextDate: number) {

    const currentDate = Number(new Date());

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const tomorrowValue = tomorrow.setHours(0, 0, 0, 0);

    if (nextDate < tomorrowValue) { // is today

      return 0;

    }

    // if not today - always round up

    return Math.ceil((nextDate - currentDate) / (1000 * 60 * 60 * 24));

  }

}
