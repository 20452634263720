export const appRouting = [

  {
    name: 'app-root',
    url: '/',
    redirectTo: 'app-my-trainings',
  },

  {
    name: 'app-login.**',
    url: '/login',
    loadChildren: () => import('./components/login/login.module').then((m) => m.LoginModule),
  },

  // technicalPages
  {
    name: 'app-rolelessUserPage.**',
    url: '/no-role-assigned',
    loadChildren: () => import('./components/technicalPages/rolelessUserPage/rolelessUserPage.module').then((m) => m.RolelessUserModule),
  },

  {
    name: 'app-errorPage.**',
    url: '/500',
    loadChildren: () => import('./components/technicalPages/errorPage/errorPage.module').then((m) => m.ErrorPageModule),
  },

  {
    name: 'app-notFound.**',
    url: '/404',
    loadChildren: () => import('./components/technicalPages/notFound/notFound.module').then((m) => m.NotFoundModule),
  },

  {
    name: 'app-technicalBreak.**',
    url: '/technical-break',
    loadChildren: () => import('./components/technicalPages/technicalBreak/technicalBreak.module').then((m) => m.TechnicalBreakModule),
  },

  // mainPages

  {
    name: 'app-my-trainings.**',
    url: '/my-trainings',
    loadChildren: () => import('./components/trainings/myTrainings/myTrainings.module').then((m) => m.MyTrainingsModule),
  },
  {
    name: 'app-manage-trainings.**',
    url: '/manage-trainings',
    loadChildren: () => import('./components/trainings/manage/manage.module').then((m) => m.ManageModule),
  },

  {
    name: 'app-browse.**',
    url: '/browse',
    loadChildren: () => import('./components/trainings/browse/browse.module').then((m) => m.BrowseModule),
  },

  {
    name: 'app-profile.**',
    url: '/profile/:id',
    loadChildren: () => import('./components/profile/profile.module').then((m) => m.ProfileModule),
  },

  {
    name: 'app-admin.**',
    url: '/admin',
    loadChildren: () => import('./components/admin/admin.module').then((m) => m.AdminModule),
  },

  {
    name: 'app-assessment.**',
    url: '/assessment',
    loadChildren: () => import('./components/assessment/assessment.module').then((m) => m.AssessmentModule),
  },

  {
    name: 'app-training-info.**',
    url: '/training-info/:id',
    loadChildren: () => import('./components/trainings/info/info.module').then((m) => m.InfoModule),
  },

  {
    name: 'app-tests.**',
    url: '/tests',
    loadChildren: () => import('./components/tests/tests/tests.module').then((m) => m.TestsModule),
  },

  {
    name: 'app-test-info.**',
    url: '/test-info/:id',
    loadChildren: () => import('./components/tests/info/info.module').then((m) => m.InfoModule),
  },

  {
    name: 'app-manage-tests.**',
    url: '/manage-tests',
    loadChildren: () => import('./components/tests/manage/manage.module').then((m) => m.ManageModule),
  },

  {
    name: 'app-tests-start.**',
    url: '/start-test',
    loadChildren: () => import('./components/tests/start/start.module').then((m) => m.StartModule),
  },
  {
    name: 'app-finished-tests-start.**',
    url: '/start-assignment',
    data: {
      canActivate: {
        finishedTest: true,
      },
    },
  },

];

