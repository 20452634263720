import {Pipe, PipeTransform} from '@angular/core';
import {RouterService} from '../../../core/router/router.service';

@Pipe({
  name: 'isActiveRoute',
  pure: false,
})

export class IsActiveRoutePipe implements PipeTransform {

  constructor(
      private routerService: RouterService,
  ) {

  }

  transform(routeName: string) {

    const onCreateName = this.routerService.onCreateStateDeclaration.name || '';

    return onCreateName === routeName || onCreateName.includes(routeName);

  }

}
