<div class="root" [ui-view]="'rootView'">

    <mat-progress-bar mode="query" *ngIf="eventsService.showProgressBar"></mat-progress-bar>

    <ng-container *ngIf="oauthService.isLoggedIn && router.locationService.url() !== '/login'">

        <div class="root-content">

            <app-shared-header></app-shared-header>

            <ui-view></ui-view>

        </div>

        <app-shared-footer></app-shared-footer>

    </ng-container>

</div>
