export interface SnackBarMessages {
  [key: string]: string;
}

const ERROR_MESSAGES: SnackBarMessages = {

  QUESTIONS_COUNT_LESS_THAN_REQUIRED: 'Please fix number of questions!',
  MIN_QUESTIONS_COUNT: 'Please, add tasks. Number of questions in the test can\'t be less than 1',
  JOB_FAILED: 'Error! Job can not be launched',
  TEST_FAILED: 'Failed!',
  SOMETHING_WRONG: 'Something wrong, try again',
  SAME_TAG_TRAINING: 'You\'re already participating another training with the same unique tag',
  COACH_IS_BUSY_AT_ANOTHER_TRAINING: 'Сoach is having a lesson in another training at this time!',
  INTERSECTION_WITH_ANOTHER_SLOT: 'Interviewer has another slot at this time!',

};

const WARNING_MESSAGES: SnackBarMessages = {

  TEST_WARNING: 'Test results will be lost after going to other page!',
  SUBSCRIBE_WARNING: 'Intersection of lesson(s) with another training',

};

const SUCCESS_MESSAGES: SnackBarMessages = {

  LOCATION_CREATED: 'Success! Location created!',
  LOCATION_UPDATED: 'Success! Location updated!',
  EMAIL_HAS_BEEN_SEND: 'Success! Email has been sent',
  STATISTIC_REQUEST: 'Statistic sent to your email!',
  JOB_LOUNCHED: 'Success! Job has been launched',
  FILE_DELETED: 'File deleted!',
  FILE_ADDED: 'File added!',
  MESSAGE_SENT: 'Message sent!',
  SUBSCRIPTION_REJECTED: 'Subscription rejected!',
  SUBSCRIPTION_APPROVED: 'Subscription approved!',
  USER_SUBSCRIBED: 'You subscribed!',
  USER_UNSUBSCRIBED: 'You unsubscribed!',
  WAIT_APPROVAL: 'Please wait for approval!',
  IN_WAITING_LIST: 'You are in waiting list!',
  QUESTION_SENT: 'Your question is sent',
  USER_IN_WAITING_LIST: 'Success! User is now in a waiting list',
  USER_IN_SAME_TAG_TRAINING: 'User is already participating another training with the same unique tag',
  LINK_ADDED: 'Link added!',
  LINK_EDITED: 'Link edited!',
  LINK_DELETED: 'Link deleted!',
  NO_CHANGES_IN_TRAINING: 'There are no changes made during training edit',
  COMMENT_ADDED: 'Comment added!',
  REQUEST_SENT: 'Request sent!',
  FEEDBACK_SENT: 'Feedback sent!',
  DELETE_LOCATION: 'Location deleted!'
};

const TASK_SUCCESS_MESSAGES: SnackBarMessages = {

  TASK_ADDED: 'Task added!',
  TASK_EDITED: 'Task edited!',
  TASK_DELETED: 'Task deleted!',

};

const TEST_SUCCESS_MESSAGES: SnackBarMessages = {

  TEST_CREATED: 'Test created!',
  TEST_EDITED: 'Test edited!',
  TEST_APPROVED: 'Test approved!',
  TEST_ENABLED: 'Test enabled!',
  TEST_DISABLED: 'Test disabled!',
  TEST_SUBMITED: 'Test submitted for approval!',
  TEST_PASSED: 'Passed!',
  TEST_ASSIGNED: 'Test assigned!',
  TEST_REJECTED: 'Test rejected!',
  TEST_DEASSIGNED: 'Test deassigned!',
  TEST_ASSIGNED_TO_USER: 'Test assigned to you!',

};

const LESSON_SUCCESS_MESSAGES: SnackBarMessages = {

  LESSON_DELETED: 'Lesson deleted!',
  LESSON_DELETED_SENT_APPROVAL: 'Lesson sent for deletion approval!',
  LESSON_EDITED: 'Lesson edited!',
  LESSON_EDITED_SENT_APPROVAL: 'Edited lesson sent to approval!',
  LESSON_ADD: 'Lesson added!',
  LESSON_APPROVED: 'Lesson approved!',
  LESSON_ADD_SENT_APPROVAL: 'New lesson sent to approval!',
  LESSON_DELETION_REJECTED: 'Lesson deletion rejected!',
  LESSON_ADD_REJECTED: 'Added lesson rejected!',
  LESSON_EDITED_REJECTED: 'Edited lesson rejected!',

};

const TRAINING_SUCCESS_MESSAGES: SnackBarMessages = {

  TRAINING_CREATED: 'Training created!',
  TRAINING_REJECTED: 'Training rejected!',
  TRAINING_DELETED: 'Training deleted!',
  TRAINING_EDITED: 'Training edited!',
  TRAINING_CLONED: 'Training cloned!',
  TRAINING_SENT_FOR_DELETION: 'Training sent for deletion!',
  TRAINING_SENT_FOR_APPROVAL: 'Training sent to approval!',
  TRAINING_DELETION_REJECTED: 'Training deletion rejected!',
  TRAINING_APPROVED: 'Training approved!',
  TRAINING_ASSIGNED_TO_USER: 'Training assigned to users!',
  TRAINING_ASSIGNED_BY_FILTER: 'Training assigned by filters!',
  TRAINING_ASSIGNED_BY_HR: 'Assignment sent to approval!',
  TRAINING_CALENDAR_REPAIRED: 'Calendar events regenerated',
};

const ASSESSMENT_SUCCESS_MESSAGES: SnackBarMessages = {

  PAIR_ADDED: 'Pair added!',
  PAIR_EDITED: 'Pair edited!',
  PAIR_DELETED: 'Pair deleted!',
  SLOT_ADDED: 'Slot added!',
  SLOT_EDITED: 'Slot edited!',
  SLOT_DELETED: 'Slot deleted!',
  SLOT_BOOKED: 'Slot booked!',
  SLOT_UNBOOKED: 'Slot unbooked!',
  SLOT_IS_OCCUPIED: 'Slot has been occupied. Please try another one.',
  FEEDBACK_CREATED: 'FeedBack published.',
};

export {
  ERROR_MESSAGES,
  WARNING_MESSAGES,
  SUCCESS_MESSAGES,

  TASK_SUCCESS_MESSAGES,
  TEST_SUCCESS_MESSAGES,
  LESSON_SUCCESS_MESSAGES,
  TRAINING_SUCCESS_MESSAGES,
  ASSESSMENT_SUCCESS_MESSAGES,
};
