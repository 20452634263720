import {StateObject, Transition, TransitionService} from '@uirouter/core';
import {OauthService} from '../../services/other/oauth.service';

export default function adminModuleGuard(transitionService: TransitionService) {

  const criteria = {
    to(state: StateObject) {
      return state.data && state.data.canActivate && state.data.canActivate.adminModuleGuard;
    },
  };

  const redirect = (transition: Transition) => {
    const $state = transition.router.stateService;
    const oauthService: OauthService = transition.injector().get(OauthService);

    const routeName: string = transition.to().name || '';

    const routeHR = () => {
      if ($state.get(routeName).data.access.allowHR) {
        return;
      } else if (oauthService.userRoles.isRunner) {
        return routeRunner();
      } else if (oauthService.userRoles.isAssistant) {
        return routeAssistant();
      } else {
        return $state.target('app-admin.statistics.user');
      }
    };

    const routeRunner = () => {
      if ($state.get(routeName).data.access.allowRunner) {
        return;
      } else if (oauthService.userRoles.isAssistant) {
        return routeAssistant();
      } else {
        return $state.target('app-admin.approval-subscribes');
      }
    };

    const redirectToTestStat = () => {
      return $state.target('app-admin.statistics.test');
    }

    const routeTestAuthor = () => {
      if ($state.get(routeName).data.access.allowTestAuthor) {
        return;
      } else if (oauthService.userRoles.isHR) {
        return routeHR();
      } else if (oauthService.userRoles.isRunner) {
        if (routeName === 'app-admin.statistics.user') {
          return redirectToTestStat();
        }
        return routeRunner();
      } else if(oauthService.userRoles.isAssistant) {
        return routeAssistant();
      } else {
        return redirectToTestStat();
      }
    }

    const routeAssistant = () => {
      if ($state.get(routeName).data.access.allowAssistant) {
        return;
      } else {
        return $state.target('app-admin.statistics.assessment');
      }
    };

    if (oauthService.userRoles.isAdmin) {
      return;
    } else if (oauthService.userRoles.isTestsAuthor) {
      return routeTestAuthor();
    } else if (oauthService.userRoles.isHR) {
      return routeHR();
    } else if (oauthService.userRoles.isRunner) {
      return routeRunner();
    } else if (oauthService.userRoles.isAssistant) {
      return routeAssistant();
    } else {
      return $state.target('app-notFound');
    }
  };

  // @ts-ignore
  transitionService.onBefore(criteria, redirect, {priority: 999});
}
