import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timeInLoc',
  pure: true,
})
export class TimeInLocPipe implements PipeTransform {

  constructor() {}

  public transform(value: Date | string | number | undefined, format: string, timeZone: string = ''): string {
    if (!value) return '-';
    const toUse = typeof value === 'string' || typeof value === 'number' ? new Date(value) : value;
    const dateStringSplit = (new Date(toUse)).toLocaleDateString(
      'en-US',
      {
        hour: '2-digit',
        minute: '2-digit',
        month: 'short',
        day: 'numeric',
        weekday: 'long',
        year: 'numeric',
        timeZone,
      },
    ).split(/, |at /); // 'at ' for Safari date format

    switch (format) {
      case 'MMM d':
        return dateStringSplit[1];
      case 'EEEE':
        return dateStringSplit[0];
      case 'hh:mm':
        return dateStringSplit[3];
      case 'MMM d, y':
        return dateStringSplit[1] + ', ' + dateStringSplit[2];
      default:
        return dateStringSplit.join();
    }
  }
}

