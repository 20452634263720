import {NgModule} from '@angular/core';
import {DatePipe, Location} from '@angular/common';
import {DotsPipe} from './pipes/dots.pipe';
import {SanitizeByDomSanitizerPipe} from './pipes/sanitizeByDomSanitizer.pipe';
import {LazyImgDirective} from './directives/lazyLoadImg.directive';
import {ImagePreloadDirective} from './directives/imagePreload.directive';
import {ClickStopEventDirective} from './directives/clickStopEvent.directive';
import {FilterByPipe, OrderByPipe} from 'ngx-pipes';
import {CountDaysBeforeStartPipe} from './pipes/countDaysBeforeStart.pipe';
import {LibsModule} from './libs.module';
import {TrackByPropertyPipe} from './pipes/trackByProperty.pipe';
import {CheckPastDatePipe} from './pipes/checkPastDate.pipe';
import {TimeInLocPipe} from './pipes/timeInLoc.pipe';
import {CheckFutureDatePipe} from './pipes/checkFutureDate.pipe';
import {FileSizePipe} from './pipes/fileSize.pipe';
import {ObserveVisibilityDirective} from './directives/observeVisibility.directive';
import {DurationValidityDirective} from './directives/durationValidity.directive';
import {IsActiveRoutePipe} from './pipes/isActiveRoute.pipe';
import {InputSettingsDirective} from './directives/inputSettings.directive';
import {DigitsOnlyDirective} from './directives/digitsOnly.directiev';
import {SetErrorForPastLessonDirective} from './directives/set-error-for-past-lesson.directive';
import {NoWhitespaceValidator} from './directives/noWhitespaceValidator.directive';
import {ParticipantsScrollbar} from './directives/participantsScrollbar.directive';
import {MatTableResponsiveDirective} from './directives/matTableResponsive.directive';
import {ListenWindowResizeDirective} from './directives/windowResizeListener.directive';
import {MouseEnterLeaveDirective} from './directives/mouseEnterLeave.directive';
import {MillisecondsToHoursPipe} from './pipes/millisecondsToHours.pipe';

@NgModule({
  imports: [],
  declarations: [
    DotsPipe,
    FileSizePipe,
    CountDaysBeforeStartPipe,
    SanitizeByDomSanitizerPipe,
    LazyImgDirective,
    ImagePreloadDirective,
    ObserveVisibilityDirective,
    ClickStopEventDirective,
    DurationValidityDirective,
    TrackByPropertyPipe,
    CheckPastDatePipe,
    CheckFutureDatePipe,
    IsActiveRoutePipe,
    InputSettingsDirective,
    DigitsOnlyDirective,
    SetErrorForPastLessonDirective,
    NoWhitespaceValidator,
    ParticipantsScrollbar,
    MatTableResponsiveDirective,
    TimeInLocPipe,
    ListenWindowResizeDirective,
    MouseEnterLeaveDirective,
    MillisecondsToHoursPipe,
  ],
  exports: [
    LibsModule,
    FileSizePipe,
    CountDaysBeforeStartPipe,
    SanitizeByDomSanitizerPipe,
    LazyImgDirective,
    ImagePreloadDirective,
    ClickStopEventDirective,
    DurationValidityDirective,
    ObserveVisibilityDirective,
    TrackByPropertyPipe,
    CheckPastDatePipe,
    CheckFutureDatePipe,
    IsActiveRoutePipe,
    InputSettingsDirective,
    DigitsOnlyDirective,
    SetErrorForPastLessonDirective,
    NoWhitespaceValidator,
    ParticipantsScrollbar,
    DotsPipe,
    MatTableResponsiveDirective,
    TimeInLocPipe,
    ListenWindowResizeDirective,
    MouseEnterLeaveDirective,
    MillisecondsToHoursPipe,
  ],
  providers: [
    Location,
    SanitizeByDomSanitizerPipe,
    CountDaysBeforeStartPipe,
    DatePipe,
    FilterByPipe,
    OrderByPipe,
    CheckPastDatePipe,
    DotsPipe,
    TimeInLocPipe,
    MillisecondsToHoursPipe,
  ],
})

export class SharedModule {

}
