import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environmentExadel} from './environments/environment.exadel';

if (environmentExadel.production) {

  enableProdMode();

}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

// tslint:disable-next-line:no-console
bootstrap().catch((err) => console.log(err));
