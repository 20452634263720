export interface LessonModel {
  // fields for repeating Lesson
  day: number; // day in training time zone (from 0 to 6)
  time: number; // time in ms from 0 to 24 hours in training timezone
  prevTimePicker?: Date; // previous date in training time zone
  prevDuration?: Date; // previous duration

  // fields for single Lesson
  date: number; // date in UTC
  datePicker: Date; // date in training time zone for timepicker

  singleLesson?: boolean;
  duration: number; // time in ms from 0 to 24 hours
  inPast?: boolean;
  lessonGroupType?: LessonModel.LessonGroupTypeEnum;
  state?: LessonModel.LessonStateEnum;
  place: string;
  prevLessonId?: number;
  setAttendance?: boolean;
}

export namespace LessonModel {
  export type LessonGroupTypeEnum = 'CURRENT' | 'FUTURE';
  export const LessonGroupTypeEnum = {
    CURRENT: 'CURRENT' as LessonGroupTypeEnum,
    FUTURE: 'FUTURE' as LessonGroupTypeEnum,
  };

  export type LessonStateEnum = 'PAST' | 'IN_PROGRESS' | 'NONE';
  export const LessonStateEnum = {
    PAST: 'PAST' as LessonStateEnum,
    IN_PROGRESS: 'IN_PROGRESS' as LessonStateEnum,
    NONE: 'NONE' as LessonStateEnum,
  };
}
