import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorsInterceptor} from './interceptors/errors.interceptor';
import {ParamsInterceptor} from './interceptors/params.interceptor';
import {DuplicateInterceptor} from './interceptors/dublicate.interceptor';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environmentExadel} from '../../environments/environment.exadel';
import {KeycloakAngularModule} from 'keycloak-angular';
import {OauthService} from './services/other/oauth.service';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    KeycloakAngularModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environmentExadel.production,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (oauthService: OauthService) => {

        return () => oauthService.init();

      },
      multi: true,
      deps: [OauthService],
    },
    {provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ParamsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: DuplicateInterceptor, multi: true},
  ],
  exports: [],
})

export class CoreModule {

}
