<header class="header" #header [class.header-active]="isSearchOpen">

    <mat-toolbar class="header-toolbar-container">

        <img class="logo" alt="ExadelLogo" src="../../../assets/images/logo.svg" width="140" height="63"
            uiSref="app-root" />

        <button mat-icon-button 
                #navMenuBtn="matMenuTrigger" 
                [class.menu-btn-active]="navMenuBtn.menuOpen"
                [matMenuTriggerFor]="navMenu"
                class="icon-button-menu">
            <mat-icon>menu</mat-icon>
            <ng-container *ngIf="isUserHasAssignedTest">
                <ng-container *ngTemplateOutlet="assignedTestBangeTemplate"></ng-container>
            </ng-container>
        </button>

        <mat-menu #navMenu="matMenu" [classList]="'header-panel-create-menu'" xPosition="after">

            <ng-template matMenuContent>

                    <menu mat-menu-item uiSref="app-my-trainings">
                        <span> {{'HEADER.TRAININGS' | translate}} </span>
                    </menu>

                    <menu mat-menu-item uiSref="app-browse">
                        <span>{{'HEADER.BROWSE' | translate}}</span>
                    </menu>

                    <menu mat-menu-item uiSref="app-tests">
                        <span> {{'COMMON.TEST' | translate}} </span>
                        <ng-container *ngIf="isUserHasAssignedTest">
                            <ng-container *ngTemplateOutlet="assignedTestBangeTemplate"></ng-container>
                        </ng-container>
                    </menu>

                    <menu mat-menu-item uiSref="app-assessment">
                        <span>{{'HEADER.ENGLISH_ASSESSMENT' | translate}}</span>
                    </menu>

                    <menu mat-menu-item uiSref="app-profile" [uiParams]="{id: oauthService.userCredsData.userId}">
                        <span>{{'HEADER.PROFILE' | translate}}</span>
                    </menu>

                    <menu mat-menu-item uiSref="app-admin" *ngIf="adminTabLabel">
                        <span>{{adminTabLabel}}</span>
                    </menu> 

            </ng-template>

        </mat-menu> 

        <nav mat-tab-nav-bar class="nav-bar-tab-header" [disablePagination]="true">

            <div class="nav-tab-container">
                <a mat-tab-link class="header-nav-link" uiSref="app-my-trainings"
                    [active]="'app-my-trainings'  | isActiveRoute">
                    {{'HEADER.TRAININGS' | translate}}
                </a>

                <a mat-tab-link class="header-nav-link" uiSref="app-browse"
                    [active]="('app-browse'  | isActiveRoute) || ('app-training-info' | isActiveRoute)">
                    {{'HEADER.BROWSE' | translate}}
                </a>

                <a mat-tab-link class="header-nav-link" uiSref="app-tests"
                    [active]="('app-tests'  | isActiveRoute) || ('app-test-info' | isActiveRoute)">
                    {{'COMMON.TEST' | translate}}
                    <ng-container *ngIf="isUserHasAssignedTest">
                        <ng-container *ngTemplateOutlet="assignedTestBangeTemplate"></ng-container>
                    </ng-container>
                </a>

                <a mat-tab-link class="header-nav-link" uiSref="app-assessment"
                    [active]="'app-assessment'  | isActiveRoute">
                    {{'HEADER.ENGLISH_ASSESSMENT' | translate}}
                </a>

                <a mat-tab-link class="header-nav-link" uiSref="app-profile"
                    [uiParams]="{id: oauthService.userCredsData.userId}" [active]="'app-profile'  | isActiveRoute">
                    {{'HEADER.PROFILE' | translate}}
                </a>

                <a mat-tab-link class="header-nav-link" uiSref="app-admin" *ngIf="adminTabLabel"
                    [active]="'app-admin' | isActiveRoute">
                    {{adminTabLabel}}
                </a>

            </div>

        </nav>

        <span class="example-spacer"></span>

        <div class="action-header-part">

            <div class="icons-part-block">

                <button mat-icon-button #createMenuBtn="matMenuTrigger" [class.menu-btn-active]="createMenuBtn.menuOpen"
                    [matMenuTriggerFor]="createMenu">
                    <mat-icon>add</mat-icon>
                </button>

                <mat-menu #createMenu="matMenu" [classList]="'header-panel-create-menu'" xPosition="before">

                    <ng-template matMenuContent>

                        <div>

                            <menu mat-menu-item uiSref="app-manage-trainings.create">
                                <mat-icon>library_add</mat-icon>
                                <span> {{'COMMON.TRAINING' | translate}} </span>
                            </menu>

                            <menu mat-menu-item uiSref="app-manage-tests.create">
                                <mat-icon>library_add</mat-icon>
                                <span>{{'COMMON.TEST' | translate}}</span>
                            </menu>

                        </div>

                    </ng-template>

                </mat-menu>

                <button mat-icon-button #searchButton (click)="toggleSearchDialog()"
                    [class.menu-btn-active]="isSearchOpen">
                    <mat-icon>search</mat-icon>
                </button>

                <button mat-icon-button #questionMenuBtn="matMenuTrigger"
                    [class.menu-btn-active]="questionMenuBtn.menuOpen" *ngIf="!oauthService.userRoles.isAdmin"
                    [matMenuTriggerFor]="questionMenu" #questionMenuTrigger="matMenuTrigger">
                    <mat-icon>contact_support</mat-icon>
                </button>

                <mat-menu #questionMenu="matMenu" [classList]="'header-panel-question-menu'" xPosition="before">

                    <ng-template matMenuContent click-stop-event>

                        <div class="question-reason" click-stop-event>
                            {{'HEADER.QUESTION_MSG' | translate}}
                        </div>

                        <form #questionForm="ngForm" autocomplete="off" name="questionForm" novalidate click-stop-event
                            [ignorePreventDefault]="true" (ngSubmit)="onSubmitQuestion()">

                            <mat-form-field appearance="outline" class="textarea-form-field">

                                <textarea matInput rows="4" name="text" [autofocus]="true" [required]="true"
                                    [minlength]="3" [(ngModel)]="question.text"></textarea>

                                <mat-error *ngIf="questionForm.controls.text?.hasError('minlength')">
                                    {{'HEADER.MIN' | translate}}
                                </mat-error>

                            </mat-form-field>

                            <button mat-stroked-button type="submit" color="primary" class="submit-question"
                                [disabled]="questionForm.invalid || (eventsService.loading$ | async)">
                                {{'COMMON.SUBMIT' | translate}}
                            </button>

                        </form>

                    </ng-template>

                </mat-menu>

                <button mat-icon-button #userMenuBtn="matMenuTrigger" [class.menu-btn-active]="userMenuBtn.menuOpen"
                    [matMenuTriggerFor]="userMenu">
                    <mat-icon>person</mat-icon>
                </button>

                <mat-menu #userMenu="matMenu" [classList]="'header-panel-profile-menu'" xPosition="before">

                    <ng-template matMenuContent>

                        <div class="menu-modal-container">
                            <h4 class="user-name">{{oauthService.userCredsData.username}}</h4>

                            <menu mat-menu-item uiSref="app-profile"
                                [uiParams]="{id: oauthService.userCredsData.userId}">
                                <mat-icon>person</mat-icon>
                                <span>{{'HEADER.PROFILE' | translate}}</span>
                            </menu>
                            <menu mat-menu-item (click)="oauthService.logOut()">
                                <mat-icon>logout</mat-icon>
                                <span>{{'HEADER.LOGOUT' | translate}}</span>
                            </menu>

                        </div>

                    </ng-template>

                </mat-menu>

            </div>

        </div>

    </mat-toolbar>

    <ng-template #assignedTestBangeTemplate> 
        <span [matTooltip]="'TESTS.INFO.ASSIGNED_TOOLTIP' | translate"
              matTooltipClass="custom-tooltip"
              matBadgeColor="warn"
              matBadge="&#8288;"
              matBadgeOverlap="false"
              class="test-badge">
        </span>
    </ng-template>

</header>