import {Injectable} from '@angular/core';
import {StateDeclaration} from '@uirouter/core/lib/state/interface';
import {Observable, ReplaySubject} from 'rxjs';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root',
})

export class RouterService {

  public routeDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  // DECLARATIONS

  // DATA

  public onCreateStateDeclaration!: StateDeclaration;

  // CORE

  constructor(
      private location: Location,
  ) {

  }

  // DEFAULT

  public locationPopstateObserve() {

    return new Observable((observer) => {

      this.location.subscribe((event) => {

        if (event.type === 'popstate') {

          observer.next(event);

        }

      });

    });

  }

}
