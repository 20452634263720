// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environmentExadel = {

  production: true,
  stage: false,
  api: '%API_URL%',
  keyCloack: {
    config: {
      url: '%KEYCLOAK_URL%',
      realm: '%REALM%',
      clientId: '%CLIENT_ID%',
    },
  },

};
