import {TransitionService} from '@uirouter/core';
import {EventsService} from '../services/other/events.service';

export default function routerTransitionOnStartConfig(transitionService: TransitionService) {

  transitionService.onStart({}, (transition) => {

    const eventsService: EventsService = transition.injector().get(EventsService);

    eventsService.setProgressState(true);

  });

}
