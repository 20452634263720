import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import {UIRouter} from '@uirouter/angular';
import {distinctUntilChanged, filter, map, pairwise, share, takeUntil, takeWhile, throttleTime} from 'rxjs/operators';
import {fromEvent, ReplaySubject} from 'rxjs';
import {EmailModel} from 'src/app/core/models';
import {OauthService} from '../../core/services/other/oauth.service';
import {EventsService} from '../../core/services/other/events.service';
import {UserService} from '../../core/services/api/user.service';
import {SnackBarMessagesService} from '../../core/services/other/snackBarMessages.service';
import {SUCCESS_MESSAGES} from '../shared/constants/snackbar-messages';
import {SearchComponent} from './modals/search/search.component';
import {TestAssignments} from '../../core/services/other/testAssignments.service';

enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

enum Direction {
  Up = 'Up',
  Down = 'Down'
}

@Component({
  selector: 'app-shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({transform: 'scaleY(0.2)'}),
      ),
      state(
        VisibilityState.Visible,
        style({transform: 'scaleY(1)'}),
      ),
      transition(`* => *`, animate('10ms ease-in')),
    ]),
  ],
})

export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private searchDialogRef!: MatDialogRef<SearchComponent>;

  // DECLARATIONS

  @ViewChild('questionMenuTrigger') questionMenuTrigger!: MatMenuTrigger;
  @ViewChild('header', {static: false}) public headerRef!: ElementRef;
  @ViewChild('searchButton', {read: ElementRef}) public searchButtonRef!: ElementRef;
  @ViewChild('navMenuBtn') navMenuBtn!: MatMenuTrigger;

  // INIT DATA

  private innerWidth!: number;
  public isVisible = true;
  public isSearchOpen: boolean = false;

  public question: EmailModel = {
    mail: '',
    text: '',
  };

  constructor(
      public router: UIRouter,
      public dialog: MatDialog,
      public oauthService: OauthService,
      public eventsService: EventsService,
      public userService: UserService,
      public snackBarMessagesService: SnackBarMessagesService,
      private renderer: Renderer2,
      private element: ElementRef,
      private cdRef: ChangeDetectorRef,
      public testAssignmentsService: TestAssignments,
  ) { }

  // HOOKS

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.setTestAssigmentsInfo();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewInit() {
    this.changeWindowSizeListeners();
  }

  public isUserHasAssignedTest: boolean = false;

  public setTestAssigmentsInfo(): void {
    this.testAssignmentsService.updateTestAssignmentsInfo();
    this.testAssignmentsService.userTestAssignmentsInfo$.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      this.isUserHasAssignedTest = res.userAssignedToTest;
      this.cdRef.detectChanges();
    })
  }

  private changeWindowSizeListeners(): void {
    if (this.innerWidth < 1024) {
      const scroll$ = fromEvent(window, 'scroll').pipe(
        throttleTime(10),
        map(() => window.pageYOffset),
        pairwise(),
        map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
        distinctUntilChanged(),
        share(),
      );

      const goingUp$ = scroll$.pipe(
        filter((direction) => direction === Direction.Up),
      );

      const goingDown$ = scroll$.pipe(
        filter((direction) => direction === Direction.Down),
      );

      goingUp$.pipe(takeWhile(() => this.innerWidth < 1024)).subscribe(() => (this.isVisible = true));
      goingDown$.pipe(takeWhile(() => this.innerWidth < 1024)).subscribe(() => (this.isVisible = false));
    }
  }

  // DEFAULT

  @HostBinding('@toggle')
  get toggle(): VisibilityState {
    if (this.isVisible) {
      this.renderer.removeClass(this.element.nativeElement, 'collapsed');
    } else {
      this.renderer.addClass(this.element.nativeElement, 'collapsed');
    }
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1023) {
      this.navMenuBtn.closeMenu();
      this.isVisible = true;
    }
    this.changeWindowSizeListeners();
  }

  public get adminTabLabel(): string {
    if (this.oauthService.userRoles.isAdmin) {
      return 'Admin';
    } else if (this.oauthService.userRoles.isHR) {
      return 'HR';
    } else if (this.oauthService.userRoles.isRunner) {
      return 'Runner';
    } else if (this.oauthService.userRoles.isAssistant) {
      return 'Assistant';
    } else if (this.oauthService.userRoles.isTestsAuthor) {
      return 'Admin';
    }
    return '';
  }

  // MODALS

  public toggleSearchDialog(): void {

    this.isSearchOpen = !this.isSearchOpen;

    if (this.searchDialogRef) {
      this.searchDialogRef.close();
    }

    if (this.isSearchOpen) {
      this.eventsService.setProgressState(true);

      import('./modals/search/search.module')
          .then((mod) => mod.SearchModule)
          .then((lazyModule) => {
            const SearchComponentRef = lazyModule.components['SearchComponent'];

            this.searchDialogRef = this.dialog.open(SearchComponentRef, {
              data: {
                positionRelativeToElement: this.headerRef,
                searchButtonRef: this.searchButtonRef,
              },
              panelClass: 'emptyContainerDialog',
              disableClose: false,
              width: '100%',
              maxWidth: '980px',
            });

            this.eventsService.setProgressState(false);

            this.searchDialogRef.afterClosed()
                .subscribe(() => {
                  this.isSearchOpen = false;
                });
          });
    }

  }

  // REQUESTS

  public onSubmitQuestion(): void {
    this.eventsService.setProgressState(true);

    this.userService.sendEmailAdmin(this.question).subscribe((res: any) => {
      this.eventsService.setProgressState(false);
      this.questionMenuTrigger.closeMenu();
      this.snackBarMessagesService.showSuccessMessage(SUCCESS_MESSAGES.QUESTION_SENT);
      this.question.text = '';
      return res;
    });
  }

}
