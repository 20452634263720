import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, finalize, first, shareReplay} from 'rxjs/operators';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';

@Injectable()

export class DuplicateInterceptor implements HttpInterceptor {

  private readonly store: Record<string, { completed: boolean, time: number, obs: Observable<HttpEvent<any>> }> = {};

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.method === 'GET') {

      return next.handle(req);

    }

    // Check if observable is in cache, otherwise call next.handle
    // Filter since we are interested in caching the response only, not progress events
    // Share replay will cache the response

    let cachedRequest = this.store[req.urlWithParams];

    const newRequest = () => {

      cachedRequest = (this.store[req.urlWithParams]
        = {completed: false, time: new Date().getTime(), obs: next.handle(req).pipe(filter((res) => res instanceof HttpResponse), shareReplay(1))});

    };

    if (cachedRequest) {

      if (new Date().getTime() - cachedRequest.time > 1000 && cachedRequest.completed) {

        newRequest();

      } else {

        cachedRequest.time = new Date().getTime();

      }

    } else {

      newRequest();

    }

    // pipe first() to cause the observable to complete after it emits the response
    // This mimics the behaviour of Observables returned by Angular's httpClient.get()
    // And also makes toPromise work since toPromise will wait until the observable completes.

    return cachedRequest.obs.pipe(first(), finalize(() => {

      cachedRequest.time = new Date().getTime();

      cachedRequest.completed = true;

    }));

  }

}
