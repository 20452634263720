import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OauthService} from '../services/other/oauth.service';
import {KeycloakService} from 'keycloak-angular';

@Injectable()

export class ParamsInterceptor implements HttpInterceptor {

  constructor(
      protected http: HttpClient,
      protected oauthService: OauthService,
      protected keycloak: KeycloakService,
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const headers = new HttpHeaders(generateHeaders(this.oauthService, req.headers));

    const params = new HttpParams();

    const setParams = onlyExistsParams(req);

    this.keycloak.updateToken().then();

    return next.handle(req.clone({headers, params, setParams}));

  }

}

export function generateHeaders(oauthService: OauthService, prevHeaders: any) {

  const headers = {};

  if (oauthService.token) {

    setHeaders({
      Authorization: `Bearer ${oauthService.token}`,
    });

  }

  prevHeaders.forEach((h: any) => {

    const key = h;
    const val = prevHeaders.get(h);
    const header = {} as any;

    header[key] = val;

    if (key && val) {

      setHeaders(header);

    }

  });


  function setHeaders(header: any) {

    Object.assign(headers, header);

  }

  return headers;

}

export function onlyExistsParams(req: HttpRequest<any>) {

  const existsParams = {} as any;

  req.params.keys().forEach((key: any) => {

    let values = req.params.getAll(key) || [];

    values = values.filter((val: any) => val !== undefined && val !== null && val !== '');

    if (values.length) {

      if (values.length <= 1) {

        existsParams[key] = values[0];

      } else {

        existsParams[key] = values;

      }

    }

  });

  return existsParams;

}
