import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[click-stop-event]',
})

export class ClickStopEventDirective {

  // DECLARATIONS

  @Input('ignorePreventDefault') private ignorePreventDefault: boolean = false;

  // DEFAULT

  @HostListener('click', ['$event'])
  public onClick(event: Event): void {

    if (!this.ignorePreventDefault) {

      event.preventDefault();

    }

    event.stopPropagation();

  }

}
