import {Directive, ElementRef} from '@angular/core';

@Directive({selector: 'img'})

export class LazyImgDirective {

  // CORE

  constructor(
      {nativeElement}: ElementRef<HTMLImageElement>,
  ) {

    // DEFAULT

    const supports = 'loading' in HTMLImageElement.prototype;

    if (supports) {

      nativeElement.setAttribute('loading', 'lazy');

    }

  }

}
