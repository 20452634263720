import {Transition, TransitionService} from '@uirouter/core';
import {EventsService} from '../services/other/events.service';

export default function routerTransitionOnSuccessConfig(transitionService: TransitionService) {

  transitionService.onSuccess({}, (transition: Transition) => {

    const eventsService: EventsService = transition.injector().get(EventsService);

    eventsService.setProgressState(false);

  });

}
