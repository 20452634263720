import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {OperationHandlerService} from '../../core/services/api/operationHandler.service';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-shared-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FooterComponent implements OnInit, OnDestroy {

  dateNow : Date = new Date();
  formatsDateYear: string = 'y';

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  // DECLARATIONS

  // INIT DATA

  public actuatorInfoData: any = null;

  // DATA

  // CORE

  constructor(
      private operationHandlerService: OperationHandlerService,
      private cdRef: ChangeDetectorRef,
  ) {

  }

  // HOOKS

  ngOnInit(): void {

    this.getFooterData();

  }

  ngOnDestroy(): void {

    this.destroyed$.next(true);
    this.destroyed$.complete();

  }

  // DEFAULT

  getFooterData(): void {

    this.operationHandlerService.handleUsingGET().pipe(takeUntil(this.destroyed$))
        .subscribe((res) => {

          this.actuatorInfoData = res;

          this.cdRef.detectChanges();

          this.cdRef.detach();

          return res;

        });

  }

  // MODALS

  // REQUESTS

  // RESPONSES

}
