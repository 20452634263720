import {NgModule} from '@angular/core';
import {UIRouterModule} from '@uirouter/angular';
import {FooterComponent} from './footer.component';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    FooterComponent,
  ],
  imports: [
    TranslateModule,
    UIRouterModule.forChild({}),
    SharedModule,
  ],
  exports: [
    FooterComponent,
  ],
})

export class FooterModule {

}
