import {NgModule} from '@angular/core';
import {FooterModule} from './footer/footer.module';
import {HeaderModule} from './header/header.module';

@NgModule({
  declarations: [],
  imports: [
    HeaderModule,
    FooterModule,
  ],
  providers: [],
  exports: [
    HeaderModule,
    FooterModule,
  ],
})

export class ComponentsModule {

}
