import {UIRouter} from '@uirouter/angular';
import routerTransitionOnSuccessConfig from './router.transition.onSuccess.config';
import auth from './guards/auth';
import routerTransitionOnStartConfig from './router.transition.onStart.config';
import adminModuleGuard from './guards/adminModuleGuard';
import isAdminGuard from './guards/isAdminGuard';
import routerTransitionOnCreateConfig from './router.transition.onCreate.config';
import finishedTest from './guards/finishedTest';
import loginPageGuard from './guards/loginPageGuard';

export function routerConfig(router: UIRouter) {

  const transitionService = router.transitionService;

  router.urlService.rules.otherwise('/404');

  // OTHER

  routerTransitionOnStartConfig(transitionService);

  routerTransitionOnCreateConfig(transitionService);

  routerTransitionOnSuccessConfig(transitionService);

  // GUARDS

  auth(transitionService);
  loginPageGuard(transitionService);
  adminModuleGuard(transitionService);
  isAdminGuard(transitionService);
  finishedTest(transitionService);

}
