import {Pipe, PipeTransform} from '@angular/core';
import {LessonModel} from 'src/app/core/models';

@Pipe({
  name: 'checkPastDate',
  pure: true,
})

export class CheckPastDatePipe implements PipeTransform {

  constructor() {

  }

  transform(state: LessonModel.LessonStateEnum) {
    return state === LessonModel.LessonStateEnum.PAST;
  }

}
