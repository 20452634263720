import {NgModule} from '@angular/core';
import {UIRouterModule} from '@uirouter/angular';
import {HeaderComponent} from './header.component';
import {SharedModule} from '../shared/shared.module';
import {NgStringPipesModule} from 'ngx-pipes';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    HeaderComponent,
  ],
  imports: [
    TranslateModule,
    UIRouterModule.forChild({}),
    SharedModule,
    NgStringPipesModule,
  ],
  exports: [
    HeaderComponent,
  ],
})

export class HeaderModule {

}
