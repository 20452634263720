import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { OauthService } from "./oauth.service";
import { UserAssignedTestModel } from "../../models/users/UserAssignedTestModel";

export interface IUserTestAssignmentsInfo {
    userAssignedToTest: boolean;
    userTestAssignments: UserAssignedTestModel[];
}

@Injectable({
    providedIn: "root",
})
export class TestAssignments implements OnDestroy {
    public userTestAssignmentsInfo$ = new Subject<IUserTestAssignmentsInfo>();
    public userTestAssignments!: UserAssignedTestModel[];

    constructor(public oauthService: OauthService) {}

    ngOnDestroy() {}

    public setTestAssignmentsInfo(state: IUserTestAssignmentsInfo) {
        this.userTestAssignmentsInfo$.next(state);
    }

    public updateTestAssignmentsInfo() {
        this.oauthService.getCurrentUserData().then((data) => {
            const { userAssignedToTest, userTestAssignments } = data;
            this.userTestAssignments = userTestAssignments;
            this.setTestAssignmentsInfo({ userAssignedToTest, userTestAssignments });
        });
    }
}
